import { Breakpoint, Theme, useMediaQuery } from '@mui/material';
import Image, { ImageProps } from 'next/image';
import { useMemo } from 'react';

import { APP_DISPLAY_NAME } from '@sbiz/business';

import { styledProps } from '../../common/styles';
import { useConfigContext } from '../../contexts/ConfigContext';
import { Link, LinkProps } from './Link';

const IMAGE_PROPS = {
  logo: { height: 32, priority: true, ratio: 59 / 5, src: '/images/logos/smood-logo.svg' },
  icon: { height: 48, ratio: 1, src: '/images/icons/smood-icon.svg' },
} as const;

export function Brand({
  breakpoint = 'sm',
  ImageProps,
  scale = 1,
  ...props
}: {
  breakpoint?: Breakpoint;
  ImageProps?: Omit<ImageProps, 'alt' | 'height' | 'src' | 'width'>;
  scale?: number;
} & Omit<LinkProps, 'href'>) {
  const {
    smood: { urls },
  } = useConfigContext();
  const isSmall = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down(breakpoint));

  const linkProps = useMemo(() => styledProps({ position: 'relative' }, props), [props]);

  const imageProps = useMemo((): Omit<ImageProps, 'alt'> => {
    const { height: defaultHeight, ratio, ...defaultImageProps } = IMAGE_PROPS[isSmall ? 'icon' : 'logo'];

    if (ImageProps?.fill) {
      return { ...defaultImageProps, ...ImageProps };
    }

    const height = scale * defaultHeight;
    return { ...defaultImageProps, ...ImageProps, height, width: height * ratio };
  }, [ImageProps, isSmall, scale]);

  return (
    <Link href={urls.showcase} {...linkProps}>
      <Image alt={`${APP_DISPLAY_NAME} Home`} {...imageProps} style={{ transition: 'all 500ms' }} />
    </Link>
  );
}
