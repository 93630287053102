import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { ChangeEvent, useCallback, useMemo } from 'react';

import { TextField } from './TextField';

export function SearchInput({
  onChange,
  placeholder,
  search,
}: {
  onChange: (search: string) => void;
  placeholder?: string;
  search: string;
}) {
  const slotProps = useMemo(
    () => ({
      input: {
        startAdornment: (
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        ),
      },
    }),
    [],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  return (
    <TextField
      name="search"
      onChange={handleChange}
      placeholder={placeholder}
      size="small"
      slotProps={slotProps}
      type="search"
      value={search}
    />
  );
}
