import { HelpOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ReactNode, useCallback, useMemo } from 'react';

import { PageTourAnchor } from '../../common/pages';
import { useTourContext } from '../../contexts/TourContext';
import { FlexBox } from './FlexBox';

export function TourAnchor({ from, label, to }: PageTourAnchor & { label: ReactNode }) {
  const { getStep, startTour } = useTourContext();

  const isAuthorized = useMemo(() => Boolean(getStep(from)), [from, getStep]);

  const handleClick = useCallback(() => {
    startTour({ from, to });
  }, [from, to, startTour]);

  return (
    <FlexBox sx={{ alignItems: 'center' }}>
      {label}

      {isAuthorized && (
        <IconButton onClick={handleClick}>
          <HelpOutline color="info" />
        </IconButton>
      )}
    </FlexBox>
  );
}
