import { useCallback, useEffect, useRef, useState } from 'react';

import { getSessionStorageItem, removeSessionStorageItem, setSessionStorageItem } from '@sbiz/util-browser';
import { isJwtExpired } from '@sbiz/util-jwt';

import { Route, RouteOverride, RouteOverrideStorageItem, STORAGE_KEYS } from '../common';
import { COMPANY_INDEPENDANT_PATHS, isHomePathname, isRedirection, PAGES } from '../common/pages';
import { AuthUser, getCompanyId, isCompaniesAdministrator } from '../common/session';
import { useAuthUser } from './useAuthUser';
import { useGetAccessToken } from './useGetAccessToken';
import { useRoute } from './useRoute';
import { useStorageItem } from './useStorageItem';

export function useAuthRedirection(options?: { from?: string }) {
  const [routeOverride, setRouteOverride] = useState<RouteOverride | null>();

  const isRedirected = useRef(false);

  const { authUser, isLoading, mutate } = useAuthUser();
  const getAccessToken = useGetAccessToken();
  const [, setIsOnLogin] = useStorageItem<boolean>(STORAGE_KEYS.onLogin);
  const [route, setRoute] = useRoute();

  const redirect = useCallback(async () => {
    if (isLoading || isRedirected.current) {
      return;
    }

    isRedirected.current = true;

    if (authUser) {
      const accessToken = await getAccessToken();
      if (accessToken) {
        authUser.accessToken = accessToken;
      }
    }

    const override = getRedirectRoute(authUser, route);
    const routeOverride = override && isRedirection(route, override) ? override : null;

    setRouteOverride(routeOverride);

    if (routeOverride) {
      const { pathname, searchParams } = route;

      if (routeOverride?.pathname === PAGES.logout.path && pathname !== PAGES.login.path && !isHomePathname(pathname)) {
        setSessionStorageItem(STORAGE_KEYS.redirectAfterLogin, { pathname, searchParams: searchParams.toString() });
      }

      if (options?.from === PAGES.login.path) {
        setIsOnLogin(true);
      }

      setRoute(routeOverride);
    }
  }, [authUser, getAccessToken, isLoading, options?.from, route, setIsOnLogin, setRoute]);

  useEffect(() => {
    redirect();
  }, [isLoading, redirect]);

  return { authUser, isLoading: routeOverride === undefined, mutate, routeOverride };
}

function getRedirectRoute(authUser: AuthUser | null, { pathname, searchParams }: Route): RouteOverride | null {
  if (authUser?.accessToken && !isJwtExpired(authUser.accessToken)) {
    const loginRedirectRoute = getSessionStorageItem<RouteOverrideStorageItem>(STORAGE_KEYS.redirectAfterLogin);

    if (loginRedirectRoute) {
      removeSessionStorageItem(STORAGE_KEYS.redirectAfterLogin);
      return {
        ...loginRedirectRoute,
        searchParams: new URLSearchParams(loginRedirectRoute.searchParams ?? undefined),
      };
    }

    if (getCompanyId(authUser)) {
      return isHomePathname(pathname) ? { pathname: PAGES.company.path, searchParams: null } : null;
    }

    if (isCompaniesAdministrator(authUser)) {
      if (COMPANY_INDEPENDANT_PATHS.includes(pathname)) {
        return null;
      }

      setSessionStorageItem(STORAGE_KEYS.redirectAfterCompanySelection, {
        pathname,
        searchParams: searchParams.toString(),
      });

      return { pathname: PAGES.companySelection.path, searchParams: null };
    }
  }

  return { pathname: PAGES.logout.path, searchParams: null };
}
