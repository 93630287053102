import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  IconButton,
  Tooltip,
  CircularProgress,
  SvgIconProps,
} from '@mui/material';
import NextLink from 'next/link';
import {
  AnchorHTMLAttributes,
  ForwardedRef,
  forwardRef,
  MouseEvent,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { styledProps, SxObject } from '../../common/styles';
import { Confirm, ConfirmCloseReason, ConfirmProps } from './Confirm';
import { Span } from './Span';

const STYLES_DEFAULT = { borderRadius: 2, fontSize: '0.97rem', px: 3, py: 1 };
const STYLES_SMALL = { borderRadius: 1.4, fontSize: '0.92rem', px: 2, py: 0.5 };

const STYLES = { default: STYLES_DEFAULT, small: STYLES_SMALL } as const;

export type ButtonLinkProps = Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'target' | 'rel'>;

export type ButtonConfirmProps = Omit<ConfirmProps, 'open'>;
export type ButtonProps = {
  confirmProps?: ButtonConfirmProps;
  Icon?: (props: SvgIconProps) => ReactNode;
  isLoading?: boolean;
  linkProps?: ButtonLinkProps;
  ref?: Ref<HTMLButtonElement>;
  size?: 'small';
} & Omit<MuiButtonProps, 'size' | keyof ButtonLinkProps>;

export const Button = forwardRef(function Button<T extends ButtonProps>(
  {
    children,
    confirmProps: propsConfirmProps,
    Icon,
    isLoading,
    linkProps,
    onClick,
    size,
    startIcon: propsStartIcon,
    title,
    ...props
  }: T,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const confirmProps = useMemo(() => {
    if (propsConfirmProps) {
      return {
        ...propsConfirmProps,
        onClose: (reason: ConfirmCloseReason) => {
          setIsConfirmOpen(false);
          propsConfirmProps?.onClose?.(reason);
        },
      };
    }
  }, [propsConfirmProps]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      if (isLoading) {
        return;
      }

      if (confirmProps) {
        setIsConfirmOpen(true);
      } else {
        onClick?.(event);
      }
    },
    [confirmProps, isLoading, onClick],
  );

  const debouncedHandleClick = useDebouncedCallback(handleClick, 500, { leading: true, trailing: false });

  const progress = useMemo(() => {
    if (isLoading) {
      return <CircularProgress color="inherit" size={size === 'small' ? 18 : 24} />;
    }
  }, [isLoading, size]);

  const startIcon = useMemo(() => {
    if (propsStartIcon) {
      return isLoading ? progress : propsStartIcon;
    }
  }, [isLoading, progress, propsStartIcon]);

  const buttonProps = useMemo(() => {
    const rel = linkProps?.target === '_blank' ? 'noreferrer' : undefined;

    const buttonProps: MuiButtonProps = {
      LinkComponent: NextLink,
      onClick: debouncedHandleClick,
      ref,
      rel,
      ...linkProps,
      ...props,
    };

    const styles: SxObject = {
      ...(isLoading && { cursor: 'not-allowed' }),
      '&.Mui-disabled': { pointerEvents: 'auto' },
    };

    if (Icon) {
      return styledProps(styles, buttonProps);
    }

    return styledProps({ ...styles, ...STYLES.default, ...(size && STYLES[size]) }, { ...buttonProps, startIcon });
  }, [debouncedHandleClick, Icon, isLoading, linkProps, props, ref, size, startIcon]);

  const buttonContent = useMemo(() => {
    if (Icon) {
      return (
        <IconButton color="primary" size={size} {...buttonProps}>
          {progress ?? <Icon fontSize="inherit" />}
        </IconButton>
      );
    }

    if (progress && !startIcon) {
      return (
        <MuiButton {...buttonProps}>
          <Span sx={{ visibility: 'hidden' }}>{children}</Span>
          <Span sx={{ display: 'inline-flex', justifyContent: 'center', position: 'absolute' }}>{progress}</Span>
        </MuiButton>
      );
    }

    return <MuiButton {...buttonProps}>{children}</MuiButton>;
  }, [buttonProps, children, Icon, progress, size, startIcon]);

  const button = useMemo(() => {
    if (title) {
      return (
        <Tooltip slotProps={{ tooltip: { sx: { backgroundColor: 'info.main', fontSize: 'small' } } }} title={title}>
          {buttonContent}
        </Tooltip>
      );
    }

    return buttonContent;
  }, [buttonContent, title]);

  return (
    <>
      {button}
      {confirmProps && <Confirm open={isConfirmOpen} {...confirmProps} />}
    </>
  );
});
