import { List, ListItem, ListItemText } from '@mui/material';
import { ReactNode } from 'react';

export function TableCellList({ items }: { items: ReactNode[] }) {
  return (
    <List dense>
      {items.map((item, index) => (
        <ListItem disableGutters key={index}>
          <ListItemText sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
            {item}
          </ListItemText>
        </ListItem>
      ))}
    </List>
  );
}
