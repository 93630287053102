import {
  TimePicker as MuiTimePicker,
  LocalizationProvider,
  TimePickerProps,
  renderTimeViewClock,
  TimeValidationError,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { FocusEvent, ReactNode, useRef } from 'react';

import { getLocale } from '@sbiz/util-dates';

import { useLang } from '../../hooks/useLang';
import { HELPER_TEXT_HEIGHT } from './TextField';

export type TimePickerContext = { validationError: TimePickerValidationError };
export type TimePickerValidationError = 'empty' | TimeValidationError;

export function TimePicker({
  error,
  helperText,
  onAccept,
  onBlur,
  onChange,
  required,
  slotProps,
  ...props
}: {
  error?: boolean;
  helperText?: ReactNode;
  onAccept?: (value: Date | null, context: TimePickerContext) => void;
  onBlur?: (
    event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: Date | null,
    context: TimePickerContext,
  ) => void;
  onChange?: (value: Date | null, context: TimePickerContext) => void;
  required?: boolean;
} & Omit<TimePickerProps<Date>, 'onAccept' | 'onChange'>) {
  const [lang] = useLang();

  const errorRef = useRef<TimePickerValidationError>(null);
  const valueRef = useRef<Date | null>(props?.value ?? null);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getLocale(lang)}>
      <MuiTimePicker
        onAccept={(value) => {
          onAccept?.(value, { validationError: errorRef.current });
        }}
        onChange={(value, context) => {
          const validationError = required && !value ? 'empty' : context.validationError;
          errorRef.current = validationError;
          valueRef.current = value;
          onChange?.(value, { validationError });
        }}
        slotProps={{
          ...slotProps,
          textField: {
            ...slotProps?.textField,
            error,
            helperText: helperText ?? ' ',
            onBlur: (event) => {
              onBlur?.(event, valueRef.current, { validationError: errorRef.current });
            },
            required,
            slotProps: { formHelperText: { sx: { minHeight: HELPER_TEXT_HEIGHT } } },
          },
        }}
        viewRenderers={{ hours: renderTimeViewClock, minutes: renderTimeViewClock, ...props?.viewRenderers }}
        {...props}
      />
    </LocalizationProvider>
  );
}
