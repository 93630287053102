import { ReactNode } from 'react';

import { Span } from './Span';

export function TableCellText({ children }: { children: ReactNode }) {
  return (
    <Span
      sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
      title={typeof children === 'string' ? children : undefined}
    >
      {children}
    </Span>
  );
}
