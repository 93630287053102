import { useCallback } from 'react';

import { DEFAULT_LANG, Lang } from '@sbiz/common';

import { useRoute } from './useRoute';

export function useLang() {
  const [{ lang: routeLang }, setRoute] = useRoute();

  const setLanguage = useCallback(
    (lang: Lang) => {
      if (lang !== routeLang) {
        setRoute({ lang }, { isShallow: true });
      }
    },
    [routeLang, setRoute],
  );

  return [routeLang ?? DEFAULT_LANG, setLanguage] as const;
}
