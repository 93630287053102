import { OpenInNew } from '@mui/icons-material';
import { ReactNode } from 'react';

import { Button, ButtonLinkProps } from './Button';

export function ExternalLink({
  label,
  linkProps,
  title,
}: {
  label?: ReactNode;
  linkProps: ButtonLinkProps;
  title?: string;
}) {
  return (
    <>
      {label}
      <Button
        linkProps={{ target: '_blank', ...linkProps }}
        Icon={OpenInNew}
        size="small"
        sx={{ ml: 1 }}
        title={title}
      />
    </>
  );
}
