import {
  DatePickerProps,
  DatePickerSlotProps,
  DateValidationError,
  DateView,
  PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getDateInFuture, getDateInPast } from '@sbiz/util-dates';

import { useFormatDate } from '../../hooks/useFormatDate';
import { DatePicker } from './DatePicker';
import { HELPER_TEXT_HEIGHT } from './TextField';

export type DateFieldValidationError = DateValidationError | 'empty';

const DEFAULT_MAX_DATE = getDateInFuture(100, 'years');
const DEFAULT_MIN_DATE = getDateInPast(100, 'years');

export function DateField({
  minDate = DEFAULT_MIN_DATE,
  maxDate = DEFAULT_MAX_DATE,
  onChange,
  onError,
  required,
  slotProps: propsSlotProps,
  ...props
}: {
  onChange?: (value: Date | null, context: PickerChangeHandlerContext<DateFieldValidationError>) => void;
  onError?: (error: DateFieldValidationError, date: Date | null) => void;
  required?: boolean;
} & Omit<DatePickerProps<Date>, 'onChange' | 'onError'>) {
  const { t } = useTranslation();

  const labels = useMemo(() => ({ clear: t('buttons.clear') }), [t]);

  const [error, setError] = useState<DateFieldValidationError>(null);

  const formatDate = useFormatDate();

  const helperText = useMemo(() => {
    const prefix = 'forms.fields.errors';

    if (error) {
      const dateFormat = getDateFormat(props.views);
      return t([`${prefix}.${error}`, `${prefix}.invalidDate`], {
        maxDate: formatDate(maxDate, dateFormat),
        minDate: formatDate(minDate, dateFormat),
      });
    }

    return ' ';
  }, [error, formatDate, maxDate, minDate, props.views, t]);

  const handleBlur = useCallback(() => {
    if (error) {
      const value = props?.value ? new Date(props.value) : null;
      const validationError = required && !value ? 'empty' : null;
      onChange?.(value, { validationError });
      onError?.(validationError, value);
    }
  }, [error, onChange, onError, props?.value, required]);

  const slotProps = useMemo(
    (): DatePickerSlotProps<Date, false> => ({
      ...propsSlotProps,
      ...(!required && { actionBar: { actions: ['clear'] } }),
      textField: {
        error: Boolean(error),
        helperText,
        onBlur: handleBlur,
        slotProps: { formHelperText: { sx: { minHeight: HELPER_TEXT_HEIGHT } } },
      },
    }),
    [error, handleBlur, helperText, propsSlotProps, required],
  );

  const handleChange = useCallback(
    (value: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
      const error = getError(value, context.validationError, required);

      setError(error);
      onError?.(error, value);

      if (!error) {
        onChange?.(value, context);
      }
    },
    [onChange, onError, required],
  );

  return (
    <DatePicker
      localeText={{ clearButtonLabel: labels.clear }}
      maxDate={maxDate}
      minDate={minDate}
      onChange={handleChange}
      slotProps={slotProps}
      {...props}
    />
  );
}

function getDateFormat(views?: Readonly<DateView[]>) {
  if (views) {
    switch (String([...views].sort())) {
      case 'month,year':
        return 'MMMM yyyy';
    }
  }

  return 'PP';
}

function getError(
  value: Date | null,
  validationError: DateValidationError,
  required?: boolean,
): DateFieldValidationError {
  if (required && !value) {
    return 'empty';
  }

  return validationError;
}
