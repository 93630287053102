import { useCallback, useEffect, useState } from 'react';

import { useAuthApi } from '../common/api/hooks/useAuthApi';
import { useClearCache } from '../common/api/hooks/useClearCache';
import { AuthUser, getAuthUser, patchAuthUser } from '../common/session';
import { useTargetCompanyIdQueryParam } from './useTargetCompanyIdQueryParam';

export type AuthUserMutator = (user?: Partial<AuthUser>) => Promise<void>;

export function useAuthUser(): (
  | { authUser: undefined; isLoading: true }
  | { authUser: AuthUser | null; isLoading: false }
) & { mutate: AuthUserMutator } {
  const [authUser, setAuthUser] = useState<AuthUser | null>();

  const { me } = useAuthApi();
  const clearCache = useClearCache();
  const [targetQueryParam, setTargetQueryParam] = useTargetCompanyIdQueryParam();

  const mutate = useCallback(
    async (values?: Partial<AuthUser>) => {
      if (!authUser?._id) {
        return;
      }

      let mutations = values;
      if (!mutations) {
        ({ data: mutations } = await me());
      }

      setAuthUser((currentUser) => {
        if (!currentUser) {
          return null;
        }

        const newUser = { ...currentUser, ...mutations };
        [{ _id: newUser.companyId }] = newUser.companies;

        patchAuthUser(newUser);
        return newUser;
      });
    },
    [authUser?._id, me],
  );

  const isCompanyAdmin = useCallback(
    (user: AuthUser, targetCompanyId: string) =>
      user.companies.some(({ _id: companyId }) => companyId === targetCompanyId),
    [],
  );

  const getUserOverrides = useCallback(
    async (
      user: AuthUser,
    ): Promise<Partial<Pick<AuthUser, 'companies' | 'companyId' | 'targetCompanyId'>> | undefined> => {
      const targetCompanyId = targetQueryParam?.value;

      if (targetCompanyId && targetCompanyId !== user.targetCompanyId) {
        if (isCompanyAdmin(user, targetCompanyId)) {
          return { targetCompanyId };
        }

        const { data: freshUser } = await me();
        if (freshUser && isCompanyAdmin(freshUser, targetCompanyId)) {
          const { companies } = freshUser;
          const [{ _id: companyId }] = companies;
          return { companies, companyId, targetCompanyId };
        }
      }
    },
    [isCompanyAdmin, me, targetQueryParam?.value],
  );

  const setInitialUser = useCallback(async () => {
    const user = getAuthUser();

    if (user) {
      const overrides = await getUserOverrides(user);

      if (overrides) {
        Object.assign(user, overrides);
        patchAuthUser(user);
        clearCache();
      }

      setTargetQueryParam(user.targetCompanyId);
    }

    setAuthUser(user);
  }, [clearCache, getUserOverrides, setTargetQueryParam]);

  useEffect(() => {
    setInitialUser();
  }, [setInitialUser]);

  if (authUser === undefined) {
    return { authUser: undefined, isLoading: true, mutate };
  }

  return { authUser, isLoading: false, mutate };
}
