import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps as MuiFormControlLabelProps,
} from '@mui/material';
import { SyntheticEvent, useCallback, useState } from 'react';

export type FormControlLabelProps = {
  checked?: boolean;
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
  readOnly?: boolean;
} & Omit<MuiFormControlLabelProps, 'checked' | 'defaultChecked' | 'onChange'>;

export function FormControlLabel({ checked, defaultChecked, onChange, readOnly, ...props }: FormControlLabelProps) {
  const [isChecked, setIsChecked] = useState(defaultChecked ?? false);

  const handleChange = useCallback(
    (_: SyntheticEvent, checked: boolean) => {
      if (!readOnly) {
        setIsChecked(checked);
        onChange?.(checked);
      }
    },
    [onChange, readOnly],
  );

  return (
    <MuiFormControlLabel
      checked={typeof checked === 'boolean' ? checked : isChecked}
      onChange={handleChange}
      {...props}
    />
  );
}
