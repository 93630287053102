import { useCallback, useMemo } from 'react';
import useSWR from 'swr';

import { ApiError } from '@sbiz/util-browser';

import { ApiRecord, ResourceType } from '../resources';
import { SWROptions } from '../types';
import { getFullPath } from '../utils';
import { useApi } from './useApi';
import { useClearCache } from './useClearCache';

export function useApiCache<const T extends ResourceType, TData = ApiRecord<T>>(
  resourceType: T,
  path: string | undefined,
  options?: SWROptions<TData>,
) {
  const { basePath, get } = useApi(resourceType);
  const { isPaused, isPublic, params, ...swrOptions } = options ?? {};
  const fullPath = getFullPath(basePath, path);
  const cacheKey =
    typeof path !== 'string' || isPaused?.() ? null : `${fullPath}${params ? `:${JSON.stringify(params)}` : ''}`;

  const clearCache = useClearCache();

  const clear = useCallback(
    (clearPath?: string) => {
      clearCache(resourceType, { path: clearPath });
    },
    [clearCache, resourceType],
  );

  const fetcher = useCallback(async () => {
    const { data, error } = await get<TData>(path ?? '', { isPublic, params });

    if (data) {
      return data;
    }

    throw error;
  }, [get, isPublic, params, path]);

  const { data, error, isLoading, isValidating, mutate } = useSWR<TData, ApiError>(cacheKey, fetcher, {
    revalidateOnFocus: false,
    ...swrOptions,
  });
  const fetchedData = error ? null : data;
  const isLoadingOrValidating = isLoading || isValidating;

  return useMemo(
    () => ({ clear, data: fetchedData, error, isLoading: isLoadingOrValidating, mutate }),
    [clear, error, fetchedData, isLoadingOrValidating, mutate],
  );
}
