import { createContext, ReactNode, useContext, useMemo } from 'react';

import { FeatureFlags } from '@sbiz/business';

import { useFeatureFlags } from '../hooks/useFeatureFlags';

const FeatureFlagsContext = createContext<FeatureFlags>({});

export function FeatureFlagsContextProvider({ children }: { children: ReactNode }) {
  const featureFlags = useFeatureFlags();

  const value = useMemo(() => featureFlags ?? {}, [featureFlags]);

  return <FeatureFlagsContext.Provider value={value}>{featureFlags ? children : null}</FeatureFlagsContext.Provider>;
}

export function useFeatureFlagsContext() {
  return useContext(FeatureFlagsContext);
}
